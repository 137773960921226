<script>
// Extensions
import View from "@/views/public/View";

// Mixins
import LoadSections from "@/mixins/loadSections";
import firebaseDeliveryBindOrder from "@/mixins/firebase/bindDeliveryOrder";
import getMenu from "@/mixins/getMenu";

export default {
  name: "Menu",

  metaInfo: { title: "Tienda" },

  extends: View,

  mixins: [
    firebaseDeliveryBindOrder,
    getMenu,
    LoadSections([
      "layout-header-menu",
      "public-menu",
      "layout-footer-menu-weborders",
      "layout-footer-basic",
    ]),
  ],
};
</script>
